import { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import {
  ListRestart,
  PackageSearch,
  ReceiptText,
  ShoppingBasket,
  Package,
  UserCheck,
} from 'lucide-react'

import api from '../../../services/api'
import { Menu } from '../../../components/Sidebar'
import { isAxiosError } from 'axios'

const statusList: any = {
  paid: 'Somente pagos',
  3: 'Aguardando pagamento',
  8: 'Cancelado',
  6: 'Em transporte',
  7: 'Entregue',
  11: 'Exceção de entrega',
  10: 'Faturado',
  4: 'Pagamento aprovado',
  9: 'Pagamento não aprovado',
  2: 'Pedido autorizado',
  1: 'Pedido realizado',
  5: 'Produtos em separação',
  12: 'Pronto para envio',
  13: 'Pronto para retirada',
}

export const Orders = () => {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const integrateCustomer = async ({ id }: { id: number }) => {
    try {
      const { data } = await api.get(`/manual/process/customer/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@SterilyMiddleware:token')}`,
        },
      })

      toast.success(data.message)
      await fetchOrders()
    } catch (error: any) {
      if (isAxiosError(error)) {
        if (error.response?.data?.data?.data?.faultstring) {
          return toast.error(error.response?.data?.data?.data?.faultstring)
        }
        toast.error(error.response?.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  const integrateOrder = async ({ id }: { id: number }) => {
    try {
      const { data } = await api.get(`/manual/process/order/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@SterilyMiddleware:token')}`,
        },
      })

      toast.success(data.message)
      await fetchOrders()
    } catch (error: any) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  const fetchOrders = useCallback(async () => {
    setLoading(true)
    setOrders([])

    try {
      // const response = await axios.get('http://localhost:3001/api/orders')
      const response = await api.get('/orders', {
        params: {
          page,
          limit,
        },
      })
      setOrders(response.data.orders)
    } catch (error: any) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }, [page, limit])

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  return (
    <div className="flex flex-1">
      {/* <Menu /> */}
      <div className="flex-1 flex items-start justify-center pt-10 pl-2 pr-2">
        <div className="relative overflow-x-auto shadow-md w-full">
          <div className="flex  border rounded">
            <button
              onClick={() => fetchOrders()}
              type="submit"
              className="m-2 flex justify-center items-center gap-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <ListRestart />
              Reload
            </button>

            <button
              onClick={() => {}}
              type="submit"
              className="m-2 flex justify-center items-center gap-2 rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <ReceiptText />
              Integrar Omie
            </button>

            <button
              onClick={() => {}}
              type="submit"
              className="m-2 flex justify-center items-center gap-2 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PackageSearch />
              Integrar J&T
            </button>
          </div>

          <div className="m-1 flex gap-4">
            <nav aria-label="Page navigation example">
              <ul className="inline-flex -space-x-px text-sm">
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      if (page === 1) {
                        return
                      }
                      setPage(page - 1)
                    }}
                    className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    Previous
                  </button>
                </li>
                <li>
                  <span className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    {page}
                  </span>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => setPage(page + 1)}
                    className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>

            <nav aria-label="Page navigation example">
              <ul className="inline-flex -space-x-px text-sm">
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      setLimit(10)
                    }}
                    className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    10
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      setLimit(20)
                    }}
                    className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    20
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      setLimit(50)
                    }}
                    className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    50
                  </button>
                </li>
              </ul>
            </nav>
          </div>

          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 text-center">
                  ID
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center"
                  style={{ width: '400px' }}
                >
                  Cliente
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  UTM_Source
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Pedido Yampi
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  ID Shopify
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center"
                  style={{ width: '220px' }}
                >
                  Forma de Entrega
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Valor Total
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Cliente Omie
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center"
                  style={{ width: '220px' }}
                >
                  Pedido Omie
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-center"
                  style={{ width: '200px' }}
                >
                  Integrado J&T
                </th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((order: any) => {
                console.log(order)

                return (
                  <tr
                    key={order.id}
                    className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center"
                    >
                      {order.id}
                    </th>
                    <td className="px-6 py-4 text-center">{order.customer}</td>
                    <td className="px-6 py-4 text-center">
                      {order.utm_source === 'facebook' && (
                        <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                          facebook
                        </span>
                      )}
                      {order.utm_source === 'tiktok' && (
                        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                          tiktok
                        </span>
                      )}
                      {!['facebook', 'tiktok', ''].includes(
                        order.utm_source,
                      ) && (
                        <span className="bg-orange-100 text-orange-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-orange-900 dark:text-orange-300">
                          {order.utm_source}
                        </span>
                      )}
                      {!order.utm_source && (
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                          site
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {order.status <= 3 && (
                        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                          {statusList[order.status]}
                        </span>
                      )}
                      {order.status > 3 && (
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                          {statusList[order.status]}
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 text-center text-blue-500">
                      <a
                        className="cursor-pointer"
                        href={`https://app.yampi.com.br/orders/${order.id_yampi}/details`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                          {order.order_yampi}
                        </span>
                      </a>
                    </td>
                    <td className="px-6 py-4 text-center text-blue-500">
                      <a
                        className="cursor-pointer"
                        href={order.shopify_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                          {order.shopify_id}
                        </span>
                      </a>
                    </td>
                    <td className="px-6 py-4 text-center">
                      {order.shipment_service}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {order.value_total}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {order.id_cliente_omie && (
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                          {order.id_cliente_omie}
                        </span>
                      )}
                      {!order.id_cliente_omie && (
                        <button
                          onClick={() => integrateCustomer({ id: order.id })}
                          type="submit"
                          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                        >
                          <UserCheck />
                          Integrar cliente
                        </button>
                        // <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                        //   Não integrado
                        // </span>
                      )}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {order.id_pedido_omie && (
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                          {order.id_pedido_omie}
                        </span>
                      )}
                      {!order.id_pedido_omie && (
                        <>
                          <button
                            onClick={() => integrateOrder({ id: order.id })}
                            type="submit"
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                          >
                            <ShoppingBasket />
                            Integrar pedido
                          </button>
                          {/* <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                            Não integrado
                          </span> */}
                        </>
                      )}
                    </td>
                    <td className="px-6 py-4 text-center">
                      {order.codigo_rastreamento && (
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                          {order.codigo_rastreamento}
                        </span>
                      )}
                      {!order.codigo_rastreamento && (
                        <button
                          onClick={() => alert('Integrar envio')}
                          type="submit"
                          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                        >
                          <Package />
                          Integrar envio
                        </button>
                      )}
                    </td>
                  </tr>
                )
              })}
              {orders.length === 0 && (
                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <td
                    colSpan={10}
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center"
                  >
                    {loading ? 'Carregando...' : 'Nenhum pedido encontrado'}
                  </td>
                </tr>
              )}

              {/* <tr
								key="key-test"
								className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
							>
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center"
								>
									1
								</th>
								<td className="px-6 py-4 text-center">sdsds</td>
								<td className="px-6 py-4 text-center">fdsfsdf</td>
								<td className="px-6 py-4 text-center">fdsfds</td>
								<td className="px-6 py-4 text-center">
									<span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
										fdsfdsfsd
									</span>
								</td>
								<td className="px-6 py-4 text-center">
									<span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
										Default
									</span>
								</td>
								<td className="px-6 py-4 text-center">
									<span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
										dfdsfds
									</span>
								</td>
							</tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
